"use client";

import MaxWidthWrapper from "@/components/wrappers/MaxWidthWrapper";
import { Button } from "../ui/button";
import { useI18n } from "../locales/client";

export default function LandingPageContent() {
  const t = useI18n();
  return (
    <MaxWidthWrapper>
      <div className="min-h-[20vh] rounded-3xl bg-white shadow-md ring-zinc-200 dark:bg-zinc-900 dark:ring-black">
        <div className="w-full px-8 pb-8 pt-8 text-start md:px-40 md:pb-10 md:pt-10">
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_head")}</p>
          </div>
          <h2 className="mb-3 mt-6 text-3xl font-bold md:mb-8 md:mt-10 md:text-5xl">
            {t("landing.content.h2_why")}
          </h2>
          <h3 className="mb-2 mt-4 text-2xl font-bold md:mb-4 md:mt-8 md:text-3xl">
            {t("landing.content.h3_understanding")}
          </h3>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_understanding")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_what_heic")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_what_heic")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_what_pdf")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_what_pdf")}</p>
          </div>
          <h3 className="mb-2 mt-4 text-2xl font-bold md:mb-4 md:mt-8 md:text-3xl">
            {t("landing.content.h3_benefits")}
          </h3>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_compatibility")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_compatibility")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_easy_sharing")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_easy_sharing")}</p>
          </div>
          <h2 className="mb-3 mt-6 text-3xl font-bold md:mb-8 md:mt-10 md:text-5xl">
            {t("landing.content.h2_using")}
          </h2>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_to_convert")}</p>
          </div>
          <h3 className="mb-2 mt-4 text-2xl font-bold md:mb-4 md:mt-8 md:text-3xl">
            {t("landing.content.h3_open")}
          </h3>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_accessing")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_begin")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_opening")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_once_you")}</p>
          </div>
          <h3 className="mb-2 mt-4 text-2xl font-bold md:mb-4 md:mt-8 md:text-3xl">
            {t("landing.content.h3_converting_pdf")}
          </h3>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_exporting_as_pdf")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_after_opening")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_saving")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_select")}</p>
            <p>{t("landing.content.p_by_following")}</p>
          </div>
          <h2 className="mb-3 mt-6 text-3xl font-bold md:mb-8 md:mt-10 md:text-5xl">
            {t("landing.content.h2_online_tools")}
          </h2>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_are_you_looking")}</p>
          </div>
          <h3 className="mb-2 mt-4 text-2xl font-bold md:mb-4 md:mt-8 md:text-3xl">
            {t("landing.content.h3_step_by_step")}
          </h3>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_selecting")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_begin_by")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_adjusting")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_once_your_files")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            {t("landing.content.h4_downloading")}
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_after_customizing")}</p>
            <p>{t("landing.content.p_ready_to")}</p>
          </div>
          <h3 className="mb-2 mt-4 text-2xl font-bold md:mb-4 md:mt-8 md:text-3xl">
            {t("landing.content.h3_other_popular")}
          </h3>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            Smallpdf
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_smallpdf")}</p>
          </div>
          <h4 className="mb-2 mt-2 text-xl font-bold md:mb-4 md:mt-4 md:text-2xl">
            CloudConvert
          </h4>
          <div className="flex flex-col md:mb-4 md:gap-3 ">
            <p>{t("landing.content.p_cloudconvert")}</p>
          </div>

          <div className="my-6 h-0.5 w-full bg-zinc-200"></div>

          <ul>
            <li>
              <p className="my-1 md:my-2">{t("landing.content.p_footer_1")}</p>
            </li>
            <li>
              <p className="my-1 md:my-2">{t("landing.content.p_footer_2")}</p>
            </li>
            <li>
              <p className="my-1 md:my-2">{t("landing.content.p_footer_3")}</p>
            </li>
          </ul>
        </div>
      </div>
    </MaxWidthWrapper>
  );
}
