"use client";

import React from "react";
import MaxWidthWrapper from "../wrappers/MaxWidthWrapper";
import dynamic from "next/dynamic";
// import HeicsToPdfAppDemo from "./HeicsToPdfAppDemo";
const HeicsToPdfAppDemo = dynamic(() => import("./HeicsToPdfAppDemo"), {
  ssr: false,
});

export default function HeicToPdf() {
  return (
    <MaxWidthWrapper className="mb-5 mt-5 max-w-screen-lg md:mb-10 md:mt-10">
      <div
        id="gradient-border"
        className="animate-borderGradientMove rounded-3xl bg-gradient-to-br from-red-500 via-orange-400 to-fuchsia-500 bg-[length:_400%_400%] p-1 [animation-duration:_9s]"
      >
        <div className="-ring-4- flex min-h-[20vh]  flex-col items-center justify-center rounded-3xl bg-white shadow-md  ring-zinc-200 dark:bg-zinc-900 dark:ring-black ">
          <HeicsToPdfAppDemo />
        </div>
      </div>
    </MaxWidthWrapper>
  );
}
