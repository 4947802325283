import { createI18nMiddleware } from "next-international/middleware";
import { NextRequest } from "next/server";

// Supported locales
export const LOCALES = [
  "en",
  "es",
  "zh",
  "fr",
  "it",
  "jp",
  "ko",
  "ms",
  "ru",
  // Add more locales here
] as const;

export const LOCALES_LABLE = [
  "English",
  "Español",
  "中文",
  "Français",
  "Italiano",
  "日本語",
  "한국어",
  "Melayu",
  "Русский",
] as const;

const I18nMiddleware = createI18nMiddleware({
  locales: LOCALES,
  defaultLocale: "en",
  urlMappingStrategy: "rewrite",
});

export function localeMiddleware(request: NextRequest) {
  return I18nMiddleware(request);
}
